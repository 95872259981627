import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { CollectionDataService, Collection } from '@sodatech/sdk';
import { filter, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-asset-filter-form',
    templateUrl: './asset-filter-form.component.html',
    styleUrls: ['./asset-filter-form.component.scss']
})
export class AssetFilterFormComponent implements OnInit {
    @Input() filter: { [key: string]: any };
    @Input() backgroundColor: string;
    @Input() showPreview: boolean;
    @Input() layout = 'masonry-grid';
    @Input() hideFilters: string[] = [];
    @Output() changes = new EventEmitter();
    @Output() layoutChange = new EventEmitter<string>();
    @Output() addCollections = new EventEmitter();
    @Output() backgroundColorChanged = new EventEmitter<string>();
    @Output() previewSwitch = new EventEmitter();

    availableColors = [
        '000000',
        'a67b5b',
        'B4A599',
        '006400',

        '00b100',
        'beff80',
        '08457e',
        '0f82ed',

        'f0f8ff',
        '8a2be2',
        'e32636',
        'ff6662',

        'ffa700',
        'fdee00',
        'a9a9a9',
        'ffffff'
    ];

    collapseGroup = {
        lastExpanded: '',
        viewOptions: true,
        filterSearchResult: true,
        ageGroups: true,
        collections: true
    };

    constructor(
        private sanitizer: DomSanitizer,
        private iconRegistry: MatIconRegistry,
        private translateService: TranslateService
    ) {}

    ngOnInit() {
        this.iconRegistry.addSvgIcon(
            'keyboard-arrow-down',
            this.sanitizer.bypassSecurityTrustResourceUrl(
                'assets/svgs/baseline-keyboard_arrow_down-24px.svg'
            )
        );
    }

    onGenericFilterChange(changes) {
        this.changes.emit(changes);
    }

    toggle(itemName: string) {
        // toggle current item
        this.collapseGroup[itemName] = !this.collapseGroup[itemName];
    }

    setBackgroundColor(colorName: string) {
        this.backgroundColorChanged.next(colorName);
    }

    onLayoutChange(layoutMode: string) {
        this.layout = layoutMode;
        this.layoutChange.emit(this.layout);
    }

    onAddCollectionClicked() {
        this.addCollections.emit();
    }

    onPreviewChange(preview) {
        this.previewSwitch.next();
    }
}
