import {
    Component,
    EventEmitter,
    HostListener,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import {
    ContactFormData,
    ContactFormService,
    UserDataService,
    UserService,
    WebseriesService
} from '@sodatech/sdk';
import { Webseries } from '@sodatech/sdk/lib/models/webseries';
import { TranslateService } from '@ngx-translate/core';

const SUPPORT_WEBSERIES = 325917;

@Component({
    selector: 'app-contact-form-modal',
    templateUrl: './contact-form-modal.component.html',
    styleUrls: ['./contact-form-modal.component.scss']
})
export class ContactFormModalComponent implements OnInit, OnDestroy {
    @Output() close = new EventEmitter<any>();

    contactForm: FormGroup;

    submitted: boolean = false;

    dataSubscription: Subscription;

    supportWebseries: Webseries;

    telephone: string;
    description: string;
    mail: string;

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private userDataService: UserDataService,
        private userService: UserService,
        private webseriesService: WebseriesService,
        private translateService: TranslateService,
        private contactFormService: ContactFormService
    ) {}

    async ngOnInit() {
        this.contactForm = this.fb.group({
            userName: ['', Validators.required],
            subject: ['', Validators.required],
            phoneCall: [false],
            phoneCallTime: [''],
            phoneNumber: [''],
            email: ['', [Validators.email, Validators.required]],
            message: ['', Validators.required],
            replyViaEmail: [true]
        });
        this.dataSubscription = this.contactForm
            .get('phoneCall')
            .valueChanges.subscribe(val => {
                if (val) {
                    this.contactForm
                        .get('phoneCallTime')
                        .setValidators([Validators.required]);
                    this.contactForm
                        .get('phoneNumber')
                        .setValidators([Validators.required]);
                    this.contactForm.patchValue({
                        replyViaEmail: false
                    });
                } else {
                    this.contactForm.get('phoneCallTime').setValidators([]);
                    this.contactForm.get('phoneNumber').setValidators([]);
                }
                this.contactForm.get('phoneCallTime').updateValueAndValidity();
                this.contactForm.get('phoneNumber').updateValueAndValidity();
                this.contactForm.updateValueAndValidity();
            });

        this.dataSubscription.add(
            this.contactForm
                .get('replyViaEmail')
                .valueChanges.subscribe(val => {
                    if (val) {
                        this.contactForm.patchValue({
                            phoneCall: false
                        });
                    }
                })
        );
        this.loadSupportWebseriesData();
    }

    async loadSupportWebseriesData() {
        if (this.userDataService.isLoggedIn()) {
            try {
                const user = await this.userService.getUser();
                const supportWebseries: Webseries[] = await this.webseriesService.getWebseries(
                    { parent_id: SUPPORT_WEBSERIES }
                );
                const matchedSupportWebseries = supportWebseries.find(
                    webserie =>
                        user.salesPerson &&
                        +webserie.number === user.salesPerson.id
                );
                if (matchedSupportWebseries) {
                    this.supportWebseries = matchedSupportWebseries;
                } else {
                    this.supportWebseries = await this.webseriesService.getWebserie(
                        SUPPORT_WEBSERIES
                    );
                }
            } catch {
                this.supportWebseries = await this.webseriesService.getWebserie(
                    SUPPORT_WEBSERIES
                );
            }
            this.parseSupportMetaInfo();
        } else {
            this.supportWebseries = await this.webseriesService.getWebserie(
                SUPPORT_WEBSERIES
            );
            this.parseSupportMetaInfo();
        }
    }

    parseSupportMetaInfo() {
        const splittedDescription = this.supportWebseries.description.split(
            ';'
        );
        if (splittedDescription.length === 3) {
            const firstLine = splittedDescription[0].split(':');
            if (firstLine.length === 2) {
                if (firstLine[0].indexOf('tel') !== -1) {
                    this.telephone = firstLine[1];
                } else if (firstLine[0].indexOf('mail') !== -1) {
                    this.mail = firstLine[1];
                } else if (firstLine[0].indexOf('text') !== -1) {
                    this.description = firstLine[1];
                }
            }
            const secondLine = splittedDescription[1].split(':');
            if (secondLine.length === 2) {
                if (secondLine[0].indexOf('tel') !== -1) {
                    this.telephone = secondLine[1];
                } else if (secondLine[0].indexOf('mail') !== -1) {
                    this.mail = secondLine[1];
                } else if (secondLine[0].indexOf('text') !== -1) {
                    this.description = secondLine[1];
                }
            }
            const thirdLine = splittedDescription[2].split(':');
            if (thirdLine.length === 2) {
                if (thirdLine[0].indexOf('tel') !== -1) {
                    this.telephone = thirdLine[1];
                } else if (thirdLine[0].indexOf('mail') !== -1) {
                    this.mail = thirdLine[1];
                } else if (thirdLine[0].indexOf('text') !== -1) {
                    this.description = thirdLine[1];
                }
            }
        }
    }

    closeContactForm(): void {
        this.router.navigate([{ outlets: { modal: null } }], {
            queryParamsHandling: 'merge'
        });
    }

    onSubmit(e: Event) {
        e.preventDefault();
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        } else {
            this.contactFormService.sendContactForm(
                this.transformFormValuesToContactFormData(
                    this.contactForm.value
                )
            );
            this.closeContactForm();
        }
    }

    ngOnDestroy(): void {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    }

    transformFormValuesToContactFormData(data: any): ContactFormData {
        const transformedData: ContactFormData = {
            userName: data.username,
            subject: data.subject,
            phoneCall: data.phoneCall ? true : null,
            phoneCallTime:
                data.phoneCallTime && data.phoneCallTime !== ''
                    ? data.phoneCallTime
                    : null,
            phoneNumber:
                data.phoneNumber && data.phoneNumberr !== ''
                    ? data.phoneNumber
                    : null,
            email: data.email,
            message: data.message,
            replyViaEmail: data.replyViaEmail ? true : null
        };
        let submitData = {};
        Object.keys(transformedData)
            .filter(key => transformedData[key])
            .map(key => {
                submitData = {
                    ...submitData,
                    [key]: transformedData[key]
                };
            });
        return submitData as ContactFormData;
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
        event: KeyboardEvent
    ) {
        const keyPressed = event.keyCode;
        if (keyPressed === 27) {
            this.closeContactForm();
        }
    }
}
