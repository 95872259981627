import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewsletterService } from '@sodatech/sdk';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'newsletter-request-page',
    templateUrl: './newsletter-request-page.component.html',
    styleUrls: ['./newsletter-request-page.component.scss']
})
export class NewsletterRequestPageComponent implements OnInit {
    ASSET_ID = '4249809';
    WEBSERIES_ID = '326340';
    HEIGHT = '650';

    formNewsletter: FormGroup;

    successResponse: boolean;
    failResponse: boolean;

    submitted: boolean = false;

    get currentLang() {
        return this.translate.currentLang;
    }

    constructor(
        private fb: FormBuilder,
        private newsletterService: NewsletterService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.formNewsletter = this.fb.group({
            firstName: ['', Validators.required],
            secondName: ['', Validators.required],
            email: ['', [Validators.email, Validators.required]]
        });
    }

    onSubmit(e: Event) {
        e.preventDefault();
        this.successResponse = false;
        this.failResponse = false;
        this.submitted = true;
        if (this.formNewsletter.valid) {
            const accountInfo = <NewsletterRequestModel>{
                email: this.formNewsletter.get('email').value,
                familyName: this.formNewsletter.get('secondName').value,
                givenName: this.formNewsletter.get('firstName').value,
                gender: 'male'
            };
            this.newsletterService.sendNewsletterRequest(accountInfo).subscribe(
                () => (this.successResponse = true),
                () => (this.failResponse = true)
            );
        }
    }
}

export interface NewsletterRequestModel {
    id?: number;
    email: string;
    gender?: string;
    familyName: string;
    givenName: string;
    language?: string;
}

