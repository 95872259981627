import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { SodatechTranslateLoaderWrapper } from '@sodatech/sdk';

import * as en from '@assets/i18n/en.json';
import * as de from '@assets/i18n/de.json';

const TRANSLATIONS = {
    en,
    de
};

export class TranslateUniversalLoader implements TranslateLoader {
    constructor() {}

    public getTranslation(lang: string): Observable<any> {
        return of(TRANSLATIONS[lang].default);
    }
}

export function translateLoaderFactory() {
    return new SodatechTranslateLoaderWrapper(new TranslateUniversalLoader());
}
