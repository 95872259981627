import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@shared/shared.module';
import { GenericPageTypeComponent } from '@features/content/containers';

@NgModule({
    declarations: [GenericPageTypeComponent],
    imports: [CommonModule, SharedModule]
})
export class ContentModule {}
