import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-payment-error-page',
    templateUrl: './payment-error-page.component.html',
    styleUrls: ['./payment-error-page.component.scss']
})
export class PaymentErrorPageComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
