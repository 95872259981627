import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomePageComponent } from '@features/home/containers';
import { SdkModule } from '@sodatech/sdk';

@NgModule({
    declarations: [HomePageComponent],
    imports: [CommonModule, SdkModule]
})
export class HomeModule {}
