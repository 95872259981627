import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Asset, AssetService } from '@sodatech/sdk';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import * as getSlug from 'speakingurl';

import { TranslateService } from '@ngx-translate/core';
import { AppRootService } from '@core/services';

@Component({
    selector: 'app-asset-details-page',
    templateUrl: './asset-details-page.component.html',
    styleUrls: ['./asset-details-page.component.scss']
})
export class AssetDetailsPageComponent {
    assetId$: Observable<string>;

    /**
     * Host name from which the asset is generated
     */
    private URL = '';

    assetUrlGenerator = ({ id, caption }: Asset) => {
        return '';
    };

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private assetService: AssetService,
        private uiService: AppRootService,
        private translateService: TranslateService
    ) {
        this.assetId$ = this.route.params.pipe(pluck('assetId'));

        this.assetId$.subscribe((assetId: string) => {
            this.assetService.getAsset(assetId).then((asset: Asset) => {
                this.uiService.setAdditionalPageTitle(asset.caption);
            });
        });
        this.assetUrlGenerator = ({ id, caption }: Asset) => {
            return `${this.URL}/${
                this.translateService.currentLang
            }/asset/${id}_${getSlug(caption)}`;
        };
    }

    onSimilaritySearch(asset: Asset) {
        this.router.navigate(
            [
                {
                    outlets: {
                        modal: ['similarity-search', { assetId: asset.id }]
                    }
                }
            ],
            {
                queryParamsHandling: 'merge'
            }
        );
    }
}
