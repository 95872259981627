import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-gallery-page',
    templateUrl: './gallery-page.component.html',
    styleUrls: ['./gallery-page.component.scss']
})
export class GalleryPageComponent implements OnInit {
    galleryId: number;

    constructor(private route: ActivatedRoute, private router: Router) {}

    ngOnInit() {
        this.galleryId = this.route.snapshot.params['galleryId'];
    }

    onToggleSimilaritySearch(assetId: string) {
        this.router.navigate(
            [
                '',
                {
                    outlets: {
                        modal: ['similarity-search', { assetId: assetId }]
                    }
                }
            ],
            {
                queryParamsHandling: 'merge'
            }
        );
    }
}
