import { NgModule } from '@angular/core';
import {
    RouterModule,
    Routes,
    UrlMatchResult,
    UrlSegment
} from '@angular/router';

import { I18nRootComponent } from '@core/containers';
import { UserGuardService } from '@core/guards';

import { HomePageComponent } from '@features/home/containers';
import { SearchPageComponent } from '@features/search/containers';

import { LoginComponent } from '@features/auth/components';
import {
    ConfirmAccountPageComponent,
    RegisterPageComponent,
    RequestResetPasswordPageComponent,
    ResetPasswordPageComponent
} from '@features/auth/containers';
import { AccountPageComponent } from '@features/account/containers';
import {
    CartOverviewPageComponent,
    CartPageComponent,
    CartPromotionCodePageComponent,
    CheckoutPageComponent,
    CheckoutSuccessPageComponent,
    DeferredPaymentPageComponent,
    PaymentErrorPageComponent
} from '@features/checkout/containers';
import {
    AddLightboxPageComponent,
    AssetDetailsPageComponent,
    GalleryPageComponent
} from '@features/gallery/containers';
import { GenericPageTypeComponent } from '@features/content/containers';
import { CMSPreviewComponent } from '@features/cms-preview/components';
import {
    ActivateNewsletterPageComponent,
    DeactivateNewsletterPageComponent,
    NewsletterRequestPageComponent
} from '@features/newsletter/containers';

import {
    ContactFormModalComponent,
    AssetKeywordsSelectorModalComponent
} from '@shared/components';

export function i18nLocaleMatcher(url: UrlSegment[]): UrlMatchResult {
    const availableLangs = ['de', 'en'];
    if (url.length > 0 && availableLangs.indexOf(url[0].path) > -1) {
        return {
            consumed: [url[0]],
            posParams: {
                lang: url[0]
            }
        };
    }
    return {
        consumed: []
    };
}

export function assetMatcherMatcher(url: UrlSegment[]) {
    if (url.length === 2 && url[0].path === 'asset') {
        // seo fixes
        const splittedPath = url[1].path.split('_');
        return {
            consumed: [url[0], url[1]],
            posParams: {
                assetId: {
                    ...url[1],
                    path: splittedPath[0]
                }
            }
        };
    }
    return null;
}

const routes: Routes = [
    {
        matcher: i18nLocaleMatcher,
        component: I18nRootComponent,
        children: [
            {
                path: '',
                component: HomePageComponent,
                data: {
                    descriptionTranslationId: 'pageDescription.homepage'
                }
            },
            {
                path: 'search',
                component: SearchPageComponent,
                data: {
                    titleTranslationId: 'pageTitle.search',
                    descriptionTranslationId: 'pageDescription.search',
                    hideFooter: true
                }
            },
            {
                matcher: assetMatcherMatcher,
                component: AssetDetailsPageComponent
            },
            {
                path: 'login/direct',
                component: LoginComponent,
                data: {
                    titleTranslationId: 'pageTitle.login',
                    descriptionTranslationId: 'pageDescription.login'
                }
            },
            {
                path: 'register',
                component: RegisterPageComponent,
                data: {
                    titleTranslationId: 'pageTitle.register',
                    descriptionTranslationId: 'pageDescription.register'
                }
            },
            {
                path: 'account/reset',
                component: RequestResetPasswordPageComponent,
                data: {
                    titleTranslationId: 'pageTitle.reset',
                    descriptionTranslationId: 'pageDescription.reset'
                }
            },
            {
                path: 'verify/:userId/:token',
                component: ConfirmAccountPageComponent,
                data: {
                    titleTranslationId: 'pageTitle.verify',
                    descriptionTranslationId: 'pageDescription.verify'
                }
            },
            {
                path: 'account/reset/:email/:token',
                component: ResetPasswordPageComponent,
                data: {
                    titleTranslationId: 'pageTitle.reset',
                    descriptionTranslationId: 'pageDescription.reset'
                }
            },
            {
                path: 'account/:type',
                component: AccountPageComponent,
                canActivate: [UserGuardService],
                data: {
                    titleTranslationId: 'pageTitle.account',
                    descriptionTranslationId: 'pageDescription.account'
                }
            },
            {
                path: 'cart',
                component: CartPageComponent,
                children: [
                    {
                        path: '',
                        redirectTo: 'overview',
                        pathMatch: 'full'
                    },
                    {
                        path: 'overview',
                        component: CartOverviewPageComponent,
                        canActivate: [UserGuardService],
                        data: {
                            titleTranslationId: 'pageTitle.cart.overview',
                            descriptionTranslationId:
                                'pageDescription.cart.overview'
                        }
                    },
                    {
                        path: 'promotion-code/:orderId',
                        component: CartPromotionCodePageComponent,
                        canActivate: [UserGuardService],
                        data: {
                            titleTranslationId: 'pageTitle.cart.promotionCode',
                            descriptionTranslationId:
                                'pageDescription.cart.promotionCode'
                        }
                    },
                    {
                        path: 'checkout',
                        component: CheckoutPageComponent,
                        canActivate: [UserGuardService],
                        data: {
                            titleTranslationId: 'pageTitle.cart.checkout',
                            descriptionTranslationId:
                                'pageDescription.cart.checkout'
                        }
                    },
                    {
                        path: 'download/:orderId',
                        component: CheckoutSuccessPageComponent,
                        canActivate: [UserGuardService],
                        data: {
                            titleTranslationId: 'pageTitle.cart.download',
                            descriptionTranslationId:
                                'pageDescription.cart.download'
                        }
                    }
                ]
            },
            {
                path: 'cart/deferred',
                component: DeferredPaymentPageComponent,
                canActivate: [UserGuardService],
                data: {
                    titleTranslationId: 'pageTitle.cart.deferred',
                    descriptionTranslationId: 'pageDescription.cart.deferred'
                }
            },
            {
                path: 'cart/error',
                component: PaymentErrorPageComponent,
                canActivate: [UserGuardService],
                data: {
                    titleTranslationId: 'pageTitle.cart.error',
                    descriptionTranslationId: 'pageDescription.cart.error'
                }
            },
            {
                path: 'cart/error/:cartId',
                component: PaymentErrorPageComponent,
                canActivate: [UserGuardService],
                data: {
                    titleTranslationId: 'pageTitle.cart.error',
                    descriptionTranslationId: 'pageDescription.cart.error'
                }
            },
            {
                path: 'gallery',
                component: GalleryPageComponent,
                canActivate: [UserGuardService],
                data: {
                    titleTranslationId: 'pageTitle.gallery',
                    descriptionTranslationId: 'pageDescription.gallery'
                }
            },
            {
                path: 'gallery/:galleryId',
                component: GalleryPageComponent,
                canActivate: [UserGuardService],
                data: {
                    titleTranslationId: 'pageTitle.gallery',
                    descriptionTranslationId: 'pageDescription.gallery'
                }
            },
            {
                path: 'gallery/new/:lightboxId',
                component: AddLightboxPageComponent
            },
            {
                path: 'newsletter',
                component: NewsletterRequestPageComponent,
                data: {
                    titleTranslationId: 'pageTitle.newsletter',
                    descriptionTranslationId: 'pageDescription.newsletter'
                }
            },
            {
                path: 'newsletter_deactivation',
                component: DeactivateNewsletterPageComponent,
                data: {
                    titleTranslationId: 'pageTitle.newsletter',
                    descriptionTranslationId: 'pageDescription.newsletter'
                }
            },
            {
                path: 'newsletter_confirmation',
                component: ActivateNewsletterPageComponent,
                data: {
                    titleTranslationId: 'pageTitle.newsletter',
                    descriptionTranslationId: 'pageDescription.newsletter'
                }
            },
            {
                path: ':page-type',
                component: GenericPageTypeComponent
            }
        ]
    },
    {
        path: '',
        component: I18nRootComponent
    },
    {
        path: 'login',
        component: LoginComponent,
        outlet: 'modal'
    },
    {
        path: 'contact-form',
        component: ContactFormModalComponent,
        outlet: 'modal'
    },
    {
        path: 'cms-preview/:lang/:folder/:slug',
        component: CMSPreviewComponent
    },
    {
        path: 'cms-preview/:folder/:slug',
        component: CMSPreviewComponent
    },
    {
        path: 'similarity-search',
        component: AssetKeywordsSelectorModalComponent,
        outlet: 'modal'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
