import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@shared/shared.module';

import { LoginComponent } from '@features/auth/components';
import {
    ConfirmAccountPageComponent,
    RegisterPageComponent,
    RequestResetPasswordPageComponent,
    ResetPasswordPageComponent
} from '@features/auth/containers';

@NgModule({
    declarations: [
        LoginComponent,
        ConfirmAccountPageComponent,
        RegisterPageComponent,
        RequestResetPasswordPageComponent,
        ResetPasswordPageComponent
    ],
    imports: [CommonModule, SharedModule]
})
export class AuthModule {}
