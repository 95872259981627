import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { pluck } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

export let LANGUAGE_INITIALIZED = false;

@Component({
    selector: 'app-locale-wrapper',
    templateUrl: './i18n-root.component.html'
})
export class I18nRootComponent implements OnInit {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.route.params.pipe(pluck('lang')).subscribe((lang: string) => {
            const languages = this.translate.getLangs();
            if (languages.indexOf(lang) > -1) {
                this.translate.use(lang);
            } else {
                if (!LANGUAGE_INITIALIZED) {
                    const browserLang = this.translate.getBrowserLang();
                    const redirectLang = browserLang.match(/en|de/)
                        ? browserLang
                        : this.translate.currentLang
                        ? this.translate.currentLang
                        : this.translate.getDefaultLang();
                    const cleanedUrl = this.router.url.substring(1);
                    if (cleanedUrl.length > 0) {
                        // Check if first url segment is an aux outlet
                        if (cleanedUrl.charAt(0) === '(') {
                            this.router.navigateByUrl(
                                `${redirectLang}${cleanedUrl}`
                            );
                        } else {
                            this.router.navigateByUrl(
                                `${redirectLang}/${cleanedUrl}`
                            );
                        }
                    } else {
                        this.router.navigate([redirectLang]);
                    }
                }
            }
            LANGUAGE_INITIALIZED = true;
        });
    }
}
