import {
    Component,
    HostBinding,
    Inject,
    OnDestroy,
    OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material';

import {
    Asset,
    Collection,
    CollectionDataService,
    ImageSimilarityService,
    PreferencesService
} from '@sodatech/sdk';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map, skip, startWith } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import * as getSlug from 'speakingurl';

export const DEFAULT_FILTER = {
    coll: [],
    license: '',
    orientation: '',
    color: '',
    size: '',
    sizeCustom: '',
    format: '',
    q: '',
    peopleCategory: null,
    release: null,
    exclusive: false,
    category: null,
    pgid: null,
    cop: null,
    mediaType: null,
    peopleNumber: ''
};

@Component({
    selector: 'app-search-page',
    templateUrl: './search-page.component.html',
    styleUrls: ['./search-page.component.scss'],
    providers: [DatePipe]
})
export class SearchPageComponent implements OnInit, OnDestroy {
    @HostBinding('class.page-search') className = true;

    /**
     * Asset layout
     * @type {string}
     */
    layout = 'masonry-grid';

    filter$: BehaviorSubject<any> = new BehaviorSubject<any>(DEFAULT_FILTER);

    isFilterEngaged$: Observable<boolean>;

    showSearchFilters: boolean;
    showPreview: boolean;

    dataSubscription: Subscription;

    totalItems: number = null;

    collections: Collection[];

    collapseGroup = {
        lastExpanded: '',
        viewOptions: true,
        filterSearchResult: true,
        ageGroups: true,
        collections: true
    };

    // default color is white
    backgroundColor = 'white';
    private showStickyFooter: boolean;

    queryParamsChanged = false;

    showFooter: boolean;

    isFilterEngagedWithAlamyOnly$: Observable<boolean>;

    stickyFooterOpen = false;

    assetUrlGenerator = ({ id, caption }: Asset) => {
        return '';
    };

    constructor(
        private route: ActivatedRoute,
        @Inject(DOCUMENT) private document: any,
        private translateService: TranslateService,
        private preferencesService: PreferencesService,
        private router: Router,
        private datePipe: DatePipe,
        private collectionService: CollectionDataService,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
        private imageSimilarityService: ImageSimilarityService
    ) {
        this.isFilterEngaged$ = this.filter$.pipe(
            map(filter => {
                return (
                    Object.keys(filter).filter(key => {
                        return (
                            filter[key] &&
                            filter[key].length > 0 &&
                            key !== 'color'
                        );
                    }).length > 0
                );
            })
        );

        this.isFilterEngagedWithAlamyOnly$ = this.filter$.pipe(
            map(filter => {
                const isFilterEmpty =
                    Object.keys(filter).filter(key => {
                        return (
                            key !== 'coll' &&
                            filter[key] &&
                            filter[key].length > 0 &&
                            key !== 'color'
                        );
                    }).length === 0;
                const isAlamyOnly = _.isEqual(filter['coll'], [
                    'ME-CO-18;ME-CO-421;AM-CO-0;AM-CO-0'
                ]);
                return isFilterEmpty && isAlamyOnly;
            })
        );

        this.dataSubscription = this.filter$.pipe(skip(1)).subscribe(filter => {
            if (!this.queryParamsChanged) {
                this.changeQueryParams(filter);
            } else {
                this.queryParamsChanged = false;
            }
        });

        this.dataSubscription.add(
            combineLatest(
                this.route.queryParams.pipe(
                    distinctUntilChanged((a, b) => _.isEqual(a, b)),
                    map(data => {
                        this.queryParamsChanged = true;
                        let newData = {};
                        for (let k in data) {
                            if (data[k] !== '') {
                                newData[k] = data[k];
                            }
                        }
                        return newData;
                    }),
                    map((data: any) => {
                        if (data['coll'] && !Array.isArray(data['coll'])) {
                            data['coll'] = [data['coll']];
                        }

                        if (
                            data['peopleCategory'] &&
                            !Array.isArray(data['peopleCategory'])
                        ) {
                            data['peopleCategory'] = [data['peopleCategory']];
                        }

                        if (
                            data['keywords'] &&
                            !Array.isArray(data['keywords'])
                        ) {
                            data['keywords'] = [data['keywords']];
                        }

                        if (
                            data['release'] &&
                            !Array.isArray(data['release'])
                        ) {
                            data['release'] = [data['release']];
                        }

                        if (
                            data['mediaType'] &&
                            !Array.isArray(data['mediaType'])
                        ) {
                            data['mediaType'] = [data['mediaType']];
                        }

                        if (
                            data['category'] &&
                            !Array.isArray(data['category'])
                        ) {
                            data['category'] = [data['category']];
                        }

                        if (
                            data['orientation'] &&
                            !Array.isArray(data['orientation'])
                        ) {
                            data['orientation'] = [data['orientation']];
                        }

                        if (data['aiplus']) {
                            const ids = this.imageSimilarityService.getSimilarImages();
                            if (!(ids && ids.length)) {
                                delete data['aiplus'];
                                this.imageSimilarityService.clearSimilarityResults();
                            }
                        } else {
                            this.imageSimilarityService.clearSimilarityResults();
                        }

                        const datesFilter = [
                            'creationMonthsAgo',
                            'uploadMonthsAgo',
                            'creationDateFrom',
                            'creationDateTo',
                            'uploadDateFrom',
                            'uploadDateTo'
                        ].reduce((acc, curr) => {
                            if (data[curr]) {
                                acc[curr] = data[curr];
                                delete data[curr];
                            }
                            return acc;
                        }, {});
                        Object.assign(data, { datesFilter });
                        return data;
                    })
                ),
                this.translateService.onLangChange.pipe(startWith(null))
            ).subscribe(([params, lang]) => {
                const filter = this.filter$.getValue();
                const newFilter = {
                    ...filter,
                    ...params
                };
                this.filter$.next(newFilter);
            })
        );

        iconRegistry.addSvgIcon(
            'keyboard-arrow-down',
            sanitizer.bypassSecurityTrustResourceUrl(
                'assets/svgs/baseline-keyboard_arrow_down-24px.svg'
            )
        );
    }

    ngOnInit() {
        this.preferencesService
            .observe('showSearchFilters')
            .subscribe(status => {
                this.showSearchFilters = status;
            });

        this.preferencesService.observe('showPreview').subscribe(status => {
            this.showPreview = status;
        });

        this.preferencesService
            .observe('layout')
            .subscribe(layout => (this.layout = layout || 'masonry-grid'));

        this.preferencesService
            .observe('backgroundColor')
            .subscribe(
                backgroundColor =>
                    (this.backgroundColor = backgroundColor || 'white')
            );
        this.assetUrlGenerator = ({ id, caption }: Asset) => {
            return `/${this.translateService.currentLang}/asset/${id}_${getSlug(
                caption
            )}`;
        };
    }

    onBreadcrumbRemoved(filter: any) {
        this.filter$.next({ ...filter });
    }

    toggleSearchFilters(e: Event) {
        e.preventDefault();
        this.showSearchFilters = !this.showSearchFilters;
        this.preferencesService.set(
            'showSearchFilters',
            this.showSearchFilters
        );
    }

    changeQueryParams(data) {
        let params = {};

        params['q'] = data['q'];
        params['pgid'] = data['pgid'];
        params['orgid'] = data['orgid'];
        params['cop'] = data['cop'];
        params['license'] = data['license'];
        params['orientation'] = data['orientation'];
        params['format'] = data['format'];
        params['color'] = data['color'];
        params['category'] = data['category'];
        params['cop'] = data['cop'];

        params['aiplus'] = data['aiplus'];

        if (data['size'] == 'custom') {
            params['size'] = Number(data['sizeCustom']) / 3;
        } else {
            params['size'] = data['size'];
        }

        params['coll'] = data.coll;
        params['farbe'] = data['farbe'];
        params['farbeName'] = data['farbeName'];
        params['peopleCategory'] = data['peopleCategory'];

        params['release'] = data['release'];

        params['mediaType'] = data['mediaType'];

        if (data['exclusive']) {
            params['exclusive'] = data['exclusive'];
        }

        params['peopleNumber'] = data['peopleNumber'];

        const convertDateToString = (date: Date) =>
            this.datePipe.transform(date, 'yyyy-MM-dd');

        if (data['datesFilter']) {
            Object.keys(data['datesFilter']).reduce((acc, key) => {
                if (key === 'creationMonthsAgo') {
                    acc[key] = data['datesFilter'][key];
                } else if (key === 'uploadMonthsAgo') {
                    acc[key] = data['datesFilter'][key];
                } else {
                    const date: Date = data['datesFilter'][key];
                    if (date) {
                        acc[key] = convertDateToString(date);
                    }
                }
                return acc;
            }, params);
        }

        for (let k in params) {
            if (
                params[k] === '' ||
                (Array.isArray(params[k]) && !params[k].length)
            ) {
                params[k] = null;
            }
        }
        this.router.navigate(
            [`/${this.translateService.currentLang}`, 'search'],
            { queryParams: params }
        );
    }

    onTotalItemsChanged(count: number) {
        this.totalItems = count;
    }

    ngOnDestroy(): void {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }

        this.preferencesService.set('lb_visible', false);
    }

    onChanges(changes) {
        this.filter$.next({
            ...this.filter$.getValue(),
            ...changes
        });
    }

    /*    onGenericFilterChange($event) {
            this.filter$.asObservable().pipe(take(1)).subscribe(currentFilter => {
                this.filter$.next({
                    ...currentFilter,
                    ...$event
                });
            });
        }*/
    onLayoutChange(layoutMode) {
        this.layout = layoutMode;
        this.preferencesService.set('layout', this.layout);
    }

    onAddCollections() {
        // this.router.navigate([{ outlets: { modal: ['add-collections'] } }], {
        //     queryParamsHandling: 'merge'
        // });
    }

    toggle(itemName: string) {
        // toggle current item
        this.collapseGroup[itemName] = !this.collapseGroup[itemName];
    }

    setBackgroundColor(colorName: string) {
        this.backgroundColor = colorName;
        this.preferencesService.set('backgroundColor', this.backgroundColor);
    }

    onPreviewChange(preview) {
        this.preferencesService.set('showPreview', !this.showPreview);
    }

    onToggleSimilaritySearch(asset: Asset) {
        this.router.navigate(
            [
                {
                    outlets: {
                        modal: ['similarity-search', { assetId: asset.id }]
                    }
                }
            ],
            {
                queryParamsHandling: 'merge'
            }
        );
    }

    onShowLightboxes($event) {
        this.router.navigate([
            `/${this.translateService.currentLang}`,
            'gallery'
        ]);
    }

    onShowImprint($event) {}

    async onAssetsReady({ faceted = { categories: {} }, total }) {
        /*this.authenticFilterCount = faceted.categories[GLOBAL_AUTHENTIC_COLLECTION_ID];

        this.choiceFilterCount = faceted.categories[GLOBAL_CHOICE_COLLECTION_ID];

        this.allFilterCount = total;*/
    }

    onCategoryFilterChanged(categories: string[]) {
        this.filter$.next({
            ...this.filter$.getValue(),
            category: categories
        });
    }

    onFilterByChoice() {
        this.router.navigate([{ outlets: { modal: ['choice-filter'] } }], {
            queryParamsHandling: 'merge'
        });
    }

    onToggleFooter() {
        this.showFooter = !this.showFooter;
        this.stickyFooterOpen = this.showFooter;
    }

    onFooterOutsideClick() {
        this.showFooter = false;
    }

    onAssetAction($event) {
        if ($event.action === 'support') {
            this.router.navigate([{ outlets: { modal: ['contact-form'] } }], {
                queryParamsHandling: 'merge'
            });
        }
    }
}
