import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { pluck } from 'rxjs/internal/operators';
import { Observable } from 'rxjs/internal/Observable';

@Component({
    selector: 'app-basket-promotion-code',
    templateUrl: './cart-promotion-code-page.component.html',
    styleUrls: ['./cart-promotion-code-page.component.scss']
})
export class CartPromotionCodePageComponent implements OnInit {
    orderId$: Observable<string>;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService
    ) {
        this.orderId$ = this.route.params.pipe(pluck('orderId'));
    }

    ngOnInit() {}

    onCheckout() {
        this.router.navigate([
            `/${this.translate.currentLang}`,
            'cart',
            'checkout'
        ]);
    }
}
