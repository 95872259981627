import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
    dataSubscription: Subscription = new Subscription();

    currentLang: string;

    constructor(private translate: TranslateService) {}

    ngOnInit() {
        this.currentLang = this.translate.currentLang;
        this.dataSubscription.add(
            this.translate.onLangChange.subscribe((data: LangChangeEvent) => {
                this.currentLang = data.lang;
            })
        );
    }

    ngOnDestroy(): void {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    }
}
