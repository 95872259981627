import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    ActivatedRoute,
    NavigationCancel,
    NavigationEnd,
    Router
} from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { ResponsiveService } from '@core/services';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    public searchText: string;
    logoIconUrl$: Observable<SafeUrl>;
    isRegularUp$: Observable<boolean>;
    private dontNavigate = false;
    private lastKeywordChange = 0;
    toolbarCollapsed = true;
    currentLang: string;
    dataSubscription: Subscription = new Subscription();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private translate: TranslateService,
        private responsiveService: ResponsiveService,
    ) {
        this.logoIconUrl$ = this.responsiveService
            .checkBreakpoints(['regular-up'])
            .pipe(
                map(([isRegularUp]) => {
                    return isRegularUp
                        ? this.sanitizer.bypassSecurityTrustUrl('')
                        : this.sanitizer.bypassSecurityTrustUrl('');
                })
            );

        this.isRegularUp$ = this.responsiveService
            .checkBreakpoints(['regular-up'])
            .pipe(map(([isRegularUp]) => isRegularUp));
    }

    ngOnInit() {
        this.currentLang = this.translate.currentLang;

        this.dataSubscription.add(
            this.translate.onLangChange.subscribe(data => {
                this.currentLang = data.lang;
            })
        );

        console.log(this.translate.currentLang);

        this.route.queryParams
            .pipe(
                map(data => {
                    let newData = {};
                    for (let k in data) {
                        if (data[k] !== '') {
                            newData[k] = data[k];
                        }
                    }

                    return newData;
                }),
                distinctUntilChanged(null, data => JSON.stringify(data))
            )
            .subscribe(event => {
                let q = event['q'] ? event['q'] : '';

                if (this.searchText == q) {
                    return;
                } else if (
                    this.router.url.substr(0, 11) !=
                    `/${this.translate.currentLang}/search?`
                ) {
                    this.searchText = '';
                    this.dontNavigate = true;
                }

                if (
                    !this.lastKeywordChange ||
                    new Date('now').getTime() - this.lastKeywordChange > 800
                ) {
                    this.searchText = q;
                }
            });

        this.router.events
            .pipe(
                filter(event => {
                    return (
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel
                    );
                })
            )
            .subscribe(() => {
                this.toolbarCollapsed = true;
            });
    }

    ngOnDestroy(): void {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    }

    /**
     * Called when the user indicator was clicked
     * @param isLoggedIn
     */
    onIndicatorClicked(isLoggedIn: boolean) {
        if (isLoggedIn) {
            this.router.navigate([
                `/${this.translate.currentLang}`,
                'account',
                'profile'
            ]);
        } else {
            this.router.navigate([{ outlets: { modal: ['login'] } }], {
                queryParamsHandling: 'preserve'
            });
        }
    }

    goToSearch(keyword) {
        const mergeQueryParams = this.router.url.indexOf('search') > -1;
        this.router.navigate([`/${this.translate.currentLang}`, 'search'], {
            queryParams: { q: keyword },
            queryParamsHandling: mergeQueryParams ? 'merge' : ''
        });
    }

    onContactFormClicked(e: Event) {
        e.preventDefault();
        this.router.navigate([{ outlets: { modal: ['contact-form'] } }], {
            queryParamsHandling: 'preserve'
        });
    }

    /**
     * Called when the user clicks the toggle menu button (only visible on mobile and tablet)
     */
    onToggleCollapseClicked(e: Event) {
        e.preventDefault();
        this.toolbarCollapsed = !this.toolbarCollapsed;
    }
}
