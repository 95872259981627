import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
    @Input() value: string;
    @Input() placeholderText: string;
    @Input() showEmptyOverlay = false;

    @Output() searchTextChanged = new EventEmitter<string>();

    isDesktopView: boolean;

    constructor(private deviceService: DeviceDetectorService) {
        this.isDesktopView = this.deviceService.isDesktop();
    }

    ngOnInit() {}

    onSearchTextChanged(searchText: string) {
        this.searchTextChanged.next(searchText);
    }
}
