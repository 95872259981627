import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-reset-password-page',
    templateUrl: './reset-password-page.component.html',
    styleUrls: ['./reset-password-page.component.scss']
})
export class ResetPasswordPageComponent implements OnInit {
    token: string = null;
    email: string = null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.token = this.route.snapshot.params['token'];
        this.email = this.route.snapshot.params['email'];
    }

    onResetSuccessful() {
        setTimeout(() => {
            this.router.navigate([`/${this.translate.currentLang}`]);
        }, 3000);
    }
}
