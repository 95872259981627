import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map, pluck } from 'rxjs/operators';

@Component({
    templateUrl: './generic-page-type.component.html'
})
export class GenericPageTypeComponent {
    slug$ = this.route.params.pipe(
        pluck('page-type'),
        map(slug => `pages/${slug}`)
    );

    get currentLang() {
        return this.translate.currentLang;
    }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService
    ) {}

    handleError(error) {
        if (error.status === 404) {
            this.router.navigate(['not-found']);
        }
    }
}
