import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

import { CMSService } from '@sodatech/sdk';

@Component({
    selector: 'app-cms-preview',
    templateUrl: './cms-preview.component.html',
    styleUrls: ['./cms-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CMSPreviewComponent implements OnInit {
    release$: Observable<string>;
    isInEditMode$: Observable<boolean>;

    constructor(public route: ActivatedRoute, public cmsService: CMSService) {
        this.release$ = this.route.queryParams.pipe(
            pluck('_storyblok_release')
        );
    }

    ngOnInit(): void {
        this.cmsService.checkForStoryBlokEditMode();
        this.isInEditMode$ = this.cmsService.isStoryBlokInEditMode();
    }
}
