import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsletterService } from '@sodatech/sdk';
import { Subscription } from 'rxjs';

@Component({
    selector: 'deactivate-newsletter',
    templateUrl: './deactivate-newsletter-page.component.html',
    styleUrls: ['./deactivate-newsletter-page.component.scss']
})
export class DeactivateNewsletterPageComponent implements OnDestroy {
    id: number;
    token: string;
    dataSubscription: Subscription;
    success: boolean;
    error: boolean;

    constructor(
        private route: ActivatedRoute,
        private newsletterService: NewsletterService
    ) {
        this.dataSubscription = this.route.queryParams.subscribe(params => {
            if (!params['uid'] || !params['key']) {
                this.success = false;
                return;
            }
            return this.newsletterService
                .deactivateNewsletterAccount(params['uid'], params['key'])
                .subscribe(
                    () => (this.success = true),
                    er => (this.error = true)
                );
        });
    }

    ngOnDestroy(): void {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    }
}

