import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { CartDataService } from '@sodatech/sdk';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
    selector: 'app-checkout-breadcrumbs',
    templateUrl: './checkout-breadcrumbs.component.html',
    styleUrls: ['./checkout-breadcrumbs.component.scss']
})
export class CheckoutBreadcrumbsComponent implements OnInit, OnDestroy {
    linkType: string;
    dataSubscription: Subscription;

    constructor(private translate: TranslateService, private router: Router) {
        this.dataSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const url = event.urlAfterRedirects;
                if (url.indexOf('overview') > -1) {
                    this.linkType = 'overview';
                } else if (url.indexOf('promotion-code') > -1) {
                    this.linkType = 'promotion-code';
                } else if (url.indexOf('checkout') > -1) {
                    this.linkType = 'checkout';
                } else if (url.indexOf('download') > -1) {
                    this.linkType = 'download';
                }
            }
        });
    }

    ngOnInit() {}

    get currentLang() {
        return this.translate.currentLang;
    }

    ngOnDestroy(): void {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    }
}
