import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, UserLoginService } from '@sodatech/sdk';
import { distinctUntilChanged } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-account-page',
    templateUrl: './account-page.component.html',
    styleUrls: ['./account-page.component.scss']
})
export class AccountPageComponent implements OnInit {
    public pageType = null;
    test$: Observable<any>;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public userLogin: UserLoginService,
        private authenticationService: AuthenticationService,
        public translateService: TranslateService
    ) {
        this.route.params.pipe(distinctUntilChanged()).subscribe(data => {
            this.pageType = data['type'];
        });
    }

    ngOnInit() {}

    get currentLang() {
        return this.translateService.currentLang;
    }

    /**
     * Called when the user wants to open the asset details for a specific asset
     * @param {string} assetId
     */
    onShowAssetDetails(assetId: string) {
        this.router.navigate([{ outlets: { modal: ['detail', assetId] } }], {
            queryParamsHandling: 'merge'
        });
    }

    /**
     * Called when the user wants to do a specific search
     * @param filter
     */
    onSearch(filter: any) {
        this.router.navigate(
            [`/${this.translateService.currentLang}`, 'search'],
            { queryParams: filter, queryParamsHandling: 'merge' }
        );
    }
}
