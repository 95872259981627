import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { AssetService } from '@sodatech/sdk';

import { ResponsiveService } from '@core/services';

export const GLOBAL_AUTHENTIC_COLLECTION_ID = '222';
export const GLOBAL_CHOICE_COLLECTION_ID = '2';

export const GLOBAL_CATEGORY_IDS = [
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '194',
    '222'
];

@Component({
    selector: 'app-category-filter',
    templateUrl: './category-filter.component.html',
    styleUrls: ['./category-filter.component.scss'],
    providers: [DatePipe]
})
export class CategoryFilterComponent implements OnInit, OnChanges, OnDestroy {
    @Input() filter: { [key: string]: any };
    @Input() ignoreCategory: string = null;

    @Input() hideAuthentic = false;
    @Input() hideChoice = false;

    activeChoice: boolean;
    activeAuthentic: boolean;

    isMobile$: Observable<boolean>;

    @Output() filterByChoice: EventEmitter<void> = new EventEmitter<void>();
    @Output() change: EventEmitter<string[]> = new EventEmitter<string[]>();

    allFilterCount$: Observable<number>;
    authenticFilterCount: number;
    choiceFilterCount: number;

    allCountMax: number;

    locale: string;
    dataSubscription = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private assetService: AssetService,
        private datePipe: DatePipe,
        private translateService: TranslateService,
        private responsiveService: ResponsiveService
    ) {
        this.isMobile$ = this.responsiveService
            .checkBreakpoints(['mobile'])
            .pipe(map(([isMobile]) => isMobile));
        const date = new Date();
        this.allFilterCount$ = this.assetService.getLoadedAssetsTotal();
    }

    calculateActiveCategories() {
        if (this.filter['category']) {
            const categories = this.filter['category'].filter(
                cat => cat !== this.ignoreCategory
            );
            if (categories.indexOf(GLOBAL_AUTHENTIC_COLLECTION_ID) !== -1) {
                this.activeAuthentic = true;
                this.activeChoice = false;
            } else if (
                GLOBAL_CATEGORY_IDS.find(id => categories.indexOf(id) !== -1)
            ) {
                this.activeAuthentic = false;
                this.activeChoice = true;
            } else {
                this.activeAuthentic = false;
                this.activeChoice = false;
            }
        }
    }

    ngOnInit() {
        this.dataSubscription.add(
            this.translateService.onLangChange.subscribe(
                (event: LangChangeEvent) => {
                    this.locale = event.lang;
                }
            )
        );
        this.dataSubscription.add(
            this.assetService.getLoadedFacetedTotal().subscribe(faceted => {
                this.authenticFilterCount =
                    faceted && faceted.categories
                        ? faceted.categories[GLOBAL_AUTHENTIC_COLLECTION_ID]
                        : null;
                this.choiceFilterCount =
                    faceted && faceted.categories
                        ? faceted.categories[GLOBAL_CHOICE_COLLECTION_ID]
                        : null;
            })
        );
    }

    ngOnDestroy(): void {
        this.dataSubscription.unsubscribe();
    }

    /**
     * Get the search filter configuration for a given filter
     * @param data
     */
    getSearchFilters(data: any): any {
        const searchFilters = this.assetService.getSearchFilters(data, 1, 1);

        if (data['category']) {
            searchFilters['category'] = searchFilters['category']
                ? searchFilters['category'] + ',' + data['category']
                : data['category'];
            const categories = searchFilters['category'].split(',');
            searchFilters['category'] = categories
                .filter(
                    id =>
                        (GLOBAL_CATEGORY_IDS.indexOf(id) === -1 &&
                            GLOBAL_CHOICE_COLLECTION_ID !== id) ||
                        id === this.ignoreCategory
                )
                .join(',');
        }

        return searchFilters;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['filter'] && this.filter) {
            this.calculateActiveCategories();
            /* this.assetService.getAssets(this.getSearchFilters(this.filter)).subscribe(({ faceted = { categories: {} }, total }) => {
                this.authenticFilterCount = (faceted && faceted.categories) ? faceted.categories[GLOBAL_AUTHENTIC_COLLECTION_ID] : null;

                this.choiceFilterCount = (faceted && faceted.categories) ? faceted.categories[GLOBAL_CHOICE_COLLECTION_ID] : null;

                // this.allFilterCount = total;
            });*/
        }
    }

    onFilterByChoice(e: Event) {
        e.preventDefault();
        this.filterByChoice.next();
    }

    onFilterByAuthentic(e: Event) {
        e.preventDefault();
        const filteredCategories = this.filter['category']
            ? this.filter['category'].filter(
                  id =>
                      GLOBAL_CATEGORY_IDS.indexOf(id) === -1 &&
                      id !== GLOBAL_AUTHENTIC_COLLECTION_ID
              )
            : [];
        filteredCategories.push(GLOBAL_AUTHENTIC_COLLECTION_ID);
        this.change.next(filteredCategories);
    }

    onResetFilter(e: Event) {
        e.preventDefault();
        const filteredCategories = this.filter['category']
            ? this.filter['category'].filter(
                  id =>
                      (GLOBAL_CATEGORY_IDS.indexOf(id) === -1 &&
                          id !== GLOBAL_AUTHENTIC_COLLECTION_ID) ||
                      id === this.ignoreCategory
              )
            : [];
        this.change.next(filteredCategories);
    }
}
