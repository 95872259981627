import { NgModule } from '@angular/core';
import {
    BrowserModule,
    makeStateKey,
    TransferState
} from '@angular/platform-browser';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DragulaModule } from 'ng2-dragula';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { Ng2Webstorage } from 'ngx-webstorage';
import { CollapseModule } from 'ngx-bootstrap';

import { SdkModule } from '@sodatech/sdk';

import { environment } from '@environment';
import { AppRoutingModule } from '@app/app-routing.module';

import { CoreModule } from '@core/core.module';
import { AppRootComponent } from '@core/containers/app-root/app-root.component';
import { translateLoaderFactory } from '@core/i18n';

import { FeaturesModule } from '@features/features.module';
import { SharedModule } from '@shared/shared.module';
import { BrowserCookiesModule } from '@ngx-utils/cookies/browser';
import { TransferHttpCacheModule } from '@nguniversal/common';

registerLocaleData(localeDe);

export const SB_CV = makeStateKey<number>('SB_CV');

@NgModule({
    declarations: [],
    imports: [
        BrowserModule.withServerTransition({ appId: 'frontend-scaffold-app' }),
        TransferHttpCacheModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        LayoutModule,
        DragulaModule,
        DeviceDetectorModule.forRoot(),
        Ng2Webstorage,
        CoreModule,
        FeaturesModule,
        SharedModule,
        AppRoutingModule,
        CollapseModule.forRoot(),
        BrowserCookiesModule.forRoot(),
        SdkModule.forRoot({
            baseUrl: '',

            company: {
                name: '',
                socialMedia: {
                    twitter: ''
                }
            },
            apiPath: 'https://api.visionspictures.sodatech.com/',
            imgPath: '',

            useDevApi: false,

            icons: {
                cart: '/assets/svgs/023-shopping-cart-1.svg',
                user: '/assets/svgs/021-user.svg',
                lightbox: '/assets/svgs/024-light-bulb.svg',
                addToLightbox: '/assets/svgs/024-light-bulb.svg',
                removeFromLightbox: '/assets/svgs/024-light-bulb.svg',
                contact: '/assets/svgs/020-call-center.svg',
                download: '/assets/svgs/016-download.svg',
                downloadHighRes: '/assets/svgs/016-download_HI.svg',
                downloadLowRes: '/assets/svgs/016-download_LO.svg',
                similar: '/assets/svgs/019-tilde.svg',
                exclusive: '/assets/svgs/010-crown.svg',
                share: '/assets/svgs/018-send.svg',
                question: '/assets/svgs/015-question.svg',
                choice: '/assets/svgs/choice.svg',
                authentic: '/assets/svgs/authentic.svg',
                choiceMono: '/assets/svgs/choice-mono.svg',
                authenticMono: '/assets/svgs/authentic-mono.svg',
                rm: '/assets/svgs/rm.svg',
                rf: '/assets/svgs/rf.svg',
                sort: '/assets/svgs/sort.svg',
                search: '/assets/svgs/025-search.svg',
                listView: '/assets/svgs/list-view.svg',
                camera: '/assets/svgs/camera.svg',
                cameraDe: '/assets/svgs/camera_de.svg',
                cameraEn: '/assets/svgs/camera_en.svg',
                upload: '/assets/svgs/upload.svg',
                draganddrop: '/assets/svgs/draganddrop.svg',
                linkurl: '/assets/svgs/linkurl.svg',
                aiFilled: '/assets/svgs/ai_filled.svg'
            },
            domain: environment.domain,
            storyBlok: {
                storiesEndpoint: '',
                spacesEndpoint: '',
                draftToken: '',
                token: '',
                version: ''
            },
            searchGridData: {
                assetUrlSuffix: '',
                assetNamePrefix: '',
                assetNameSuffix: ''
            },
            similaritySearch: {
                api: ''
            },
            availableLanguages: [],

            defaultCountries: [],
            primaryCountry: '',
            primaryLanguage: '',
            assetDetails: {
                preview: false,
                alwaysDisplayCalculator: false,
                displaySupportOption: false,
                hideSubscriptionText: false,
                displayRFHint: false,
                displayCreditor: false,
                credit: {
                    alwaysUseCollectionData: false
                },
                seo: {
                    rmLink: '',
                    rfLink: '',
                    descriptionSuffix: ''
                },
                useHeadlineForDescription: false
            },
            layout: {
                grid: {
                    displayAssetCaption: false
                },
                displayAsset: {
                    useNativeImage: false,
                    useLazySizes: false
                },
                cmsImage: {
                    useLazySizes: false
                }
            },
            assetSearchFilter: {
                isOccurrencesEnabled: false,
                peopleFilter: {}
            },
            ui: {
                materialSelectItemHeight: null,
                scrollToAnchorOffset: null
            },
            orderList: {
                proposal: {
                    displayArticleActions: false,
                    hideAddToCartAction: false
                },
                hideTaxesPerArticle: false
            },
            businessTypes: {},
            assetCalculator: {
                hideCommercialOrEditorial: false
            },
            additionalCollections: [],
            userFunctions: {
                showCart: null,
                directHighResDownload: null
            },
            termsLink: '',
            rendering: {
                breakpointSizesMap: {}
            }
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoaderFactory
            }
        })
    ],
    bootstrap: [AppRootComponent]
})
export class AppModule {
    constructor(private transferState: TransferState) {
        if (!this.transferState.hasKey(SB_CV)) {
            const random = new Date().getTime().toString();
            this.transferState.set(SB_CV, random);
        }
        window['sb-cv'] = this.transferState.get(SB_CV, undefined);
    }
}
