import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Meta } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class AppRootService {
    private additionalPageTitle$ = new BehaviorSubject<string>('');

    constructor(private metaService: Meta) {}

    setAdditionalPageTitle(additionalTitle: string) {
        this.additionalPageTitle$.next(additionalTitle);
    }

    getAdditionalPageTitle(): Observable<string> {
        return this.additionalPageTitle$.asObservable();
    }

    setPageDescription(description: string) {
        this.metaService.updateTag({
            name: 'description',
            content: description
        });
    }
}
