import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-add-lightbox-page',
    templateUrl: './add-lightbox-page.component.html',
    styleUrls: ['./add-lightbox-page.component.scss']
})
export class AddLightboxPageComponent implements OnInit {
    lightboxId$: Observable<string>;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.lightboxId$ = this.route.params.pipe(pluck('lightboxId'));
    }

    onLightboxAdded() {
        this.router.navigate([this.translate.currentLang, 'gallery']);
    }

    onToggleSimilaritySearch(assetId: number) {
        this.router.navigate(
            [
                {
                    outlets: {
                        modal: ['similarity-search', { assetId: assetId }]
                    }
                }
            ],
            {
                queryParamsHandling: 'merge'
            }
        );
    }
}
