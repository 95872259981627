import { NgModule } from '@angular/core';

import { AuthModule } from '@features/auth/auth.module';
import { AccountModule } from '@features/account/account.module';
import { HomeModule } from '@features/home/home.module';
import { SearchModule } from '@features/search/search.module';
import { GalleryModule } from '@features/gallery/gallery.module';
import { CheckoutModule } from '@features/checkout/checkout.module';
import { ContentModule } from '@features/content/content.module';
import { CmsPreviewModule } from '@features/cms-preview/cms-preview.module';
import { NewsletterModule } from '@features/newsletter/newsletter.module';

@NgModule({
    declarations: [],
    imports: [
        AuthModule,
        AccountModule,
        HomeModule,
        SearchModule,
        GalleryModule,
        CheckoutModule,
        ContentModule,
        CmsPreviewModule,
        NewsletterModule
    ]
})
export class FeaturesModule {}
