import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@shared/shared.module';

import {
    CartOverviewPageComponent,
    CartPageComponent,
    CartPromotionCodePageComponent,
    CheckoutPageComponent,
    CheckoutSuccessPageComponent,
    DeferredPaymentPageComponent,
    PaymentErrorPageComponent
} from '@features/checkout/containers';
import { CheckoutBreadcrumbsComponent } from '@features/checkout/components';

@NgModule({
    declarations: [
        CheckoutBreadcrumbsComponent,
        CheckoutPageComponent,
        CartPageComponent,
        CartOverviewPageComponent,
        CartPromotionCodePageComponent,
        CheckoutSuccessPageComponent,
        PaymentErrorPageComponent,
        DeferredPaymentPageComponent
    ],
    imports: [CommonModule, SharedModule]
})
export class CheckoutModule {}
