export interface Environment {
    production: boolean;
    domain: string;
    breakpoints: {
        [breakpoint: string]: string;
    };
    analytics: {
        url: string;
        id: string;
    };
}

export const environment = {
    breakpoints: {
        'mobile': '(max-width: 599px)',
        'tablet': '(min-width: 600px) and (max-width: 1024px)',
        'tablet-up': '(min-width: 600px)',
        'regular': '(min-width: 1025px) and (max-width: 1440px)',
        'regular-up': '(min-width: 1025px)',
        'large': '(min-width: 1441px)',
    }
};
