import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environment';

@Injectable({
    providedIn: 'root'
})
export class ResponsiveService {
    constructor(private breakpointObserver: BreakpointObserver) {}

    /**
     * Check which breakpoints match for a given list of breakpoints
     */
    checkBreakpoints(breakpoints: string[]): Observable<boolean[]> {
        return combineLatest(
            ...breakpoints.map(breakpoint =>
                this.breakpointObserver.observe(
                    environment.breakpoints[breakpoint]
                )
            )
        ).pipe(
            map((states: BreakpointState[]) => {
                return states.map(state => state.matches);
            })
        );
    }
}
