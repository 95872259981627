import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-deferred-payment-page',
    templateUrl: './deferred-payment-page.component.html',
    styleUrls: ['./deferred-payment-page.component.scss']
})
export class DeferredPaymentPageComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
