import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@shared/shared.module';
import { AccountPageComponent } from '@features/account/containers';

@NgModule({
    declarations: [AccountPageComponent],
    imports: [CommonModule, SharedModule]
})
export class AccountModule {}
