import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material';

import { SdkModule } from '@sodatech/sdk';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { I18nRootComponent, AppRootComponent } from '@core/containers';
import {
    FooterComponent,
    HeaderComponent,
    SearchBarComponent
} from '@core/components';

@NgModule({
    declarations: [
        AppRootComponent,
        I18nRootComponent,
        FooterComponent,
        HeaderComponent,
        SearchBarComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        DeviceDetectorModule,
        SdkModule,
        TranslateModule,
        MatIconModule
    ],
    exports: [RouterModule]
})
export class CoreModule {}
