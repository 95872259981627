import {
    Component,
    HostListener,
    Inject,
    OnInit,
    PLATFORM_ID
} from '@angular/core';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, filter, map, mergeMap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, DragAndDropService } from '@sodatech/sdk';

import { AppRootService } from '@core/services';

@Component({
    selector: 'app-root',
    templateUrl: './app-root.component.html',
    styleUrls: ['./app-root.component.scss']
})
export class AppRootComponent implements OnInit {
    private metaToKeep = [];

    /**
     * Cookie bar config
     */
    public cookieBarConfig: CookieBarConfig;

    /**
     * Flag that describes whether the footer is hidden or not
     */
    hideFooter: boolean;

    /**
     * Is Logged in
     */
    isLoggedIn: boolean;

    /**
     * BehaviourSubject of the current page title translation
     */
    currentPageTitleTranslationId$ = new BehaviorSubject<string>('');

    /**
     * BehaviourSubject of the current page description translation
     */
    currentPageDescriptionTranslationId$ = new BehaviorSubject<string>('');

    /**
     * Drag enter count (is an object currently dragged or already left the screen)
     */
    dragEnterCounter = 0;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private metaService: Meta,
        public translate: TranslateService,
        private authenticationService: AuthenticationService,
        private uiService: AppRootService,
        private dragAndDropService: DragAndDropService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('en');
        translate.use('en');
    }

    ngOnInit(): void {
        this.initDefaultMetaTags();
        // @todo: refactor
        this.router.events
            .pipe(
                map(value => {
                    return value;
                }),
                filter(event => event instanceof NavigationEnd),
                map(() => {
                    let route = this.activatedRoute;
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter(route => route.outlet === 'primary'),
                mergeMap(route => route.data)
            )
            .subscribe(routeData => {
                this.removePreviousMetaTags();

                let data = this.getMainRouteData();
                data = { ...data, ...routeData };

                this.hideFooter = !!data['hideFooter'];

                this.currentPageTitleTranslationId$.next(
                    data['titleTranslationId']
                );
                this.currentPageDescriptionTranslationId$.next(
                    data['descriptionTranslationId']
                );

                if (data['meta'] && data['meta'].length) {
                    this.metaService.addTags(data['meta']);
                }
            });

        this.translate
            .stream([
                'cookieBar.title',
                'cookieBar.content',
                'cookieBar.action'
            ])
            .subscribe(data => {
                this.cookieBarConfig = {
                    content: data['cookieBar.content'],
                    title: data['cookieBar.title'],
                    action: data['cookieBar.action']
                };
            });

        combineLatest(
            this.authenticationService.isUserLoggedOut(),
            this.authenticationService.isUserLoggedIn()
        )
            .pipe(debounceTime(200))
            .subscribe(([loggedOut, loggedIn]) => {
                if (this.isLoggedIn && !loggedIn && loggedOut) {
                    this.router.navigate([`/${this.translate.currentLang}`]);
                }
                this.isLoggedIn = loggedIn;
            });

        // Updates/translates page title on language change
        combineLatest(
            this.translate.onLangChange.pipe(map(e => e.lang)),
            this.currentPageTitleTranslationId$,
            this.uiService.getAdditionalPageTitle(),
            this.currentPageDescriptionTranslationId$
        ).subscribe(
            ([
                __,
                pageTitleTranslationId,
                additionalPageTitle,
                pageDescriptionTranslationId
            ]) => {
                this.updateLanguage();
                this.setPageTitle(pageTitleTranslationId, additionalPageTitle);
                this.setPageDescription(pageDescriptionTranslationId);
            }
        );
    }

    /**
     * Get data of the main route
     */
    private getMainRouteData() {
        // @todo: refactor
        let data = {
            title: '',
            meta: []
        };

        for (let entry of this.router.config) {
            if (entry.path == '') {
                return { ...data, ...entry.data };
            }
        }

        return data;
    }

    /**
     * Init the default meta tags
     */
    private initDefaultMetaTags() {
        // @todo: refactor
        this.metaToKeep = [];
        for (let tag of this.metaService.getTags('name')) {
            let data = {};
            for (let i = 0; i < tag.attributes.length; i++) {
                let attr = tag.attributes.item(i);
                data[attr.nodeName] = attr.nodeValue;
            }

            this.metaToKeep.push(data);
        }
    }

    private removePreviousMetaTags() {
        // @todo: refactor
        for (let tag of this.metaService.getTags('name')) {
            this.metaService.removeTag('name="' + tag['name'] + '"');
        }

        this.metaService.addTags(this.metaToKeep);
    }

    public showFullPage() {
        return this.router.url.substr(-12) !== 'mode=compact';
    }

    /**
     * Sets the page title
     */
    setPageTitle(pageTitleTranslationId: string, additionalPageTitle: string) {
        if (pageTitleTranslationId) {
            const pageTitle: string = this.translate.instant(
                pageTitleTranslationId
            );
            if (pageTitle !== pageTitleTranslationId) {
                if (additionalPageTitle) {
                    this.titleService.setTitle(
                        `${additionalPageTitle} - ${pageTitle} - sodatech`
                    );
                } else {
                    this.titleService.setTitle(`${pageTitle} - sodatech`);
                }
                return;
            }
        }

        // Title for the homepage and/or for pages without titleTranslationId
        this.titleService.setTitle(`Bildagentur | sodatech`);
    }

    /**
     * Sets the page description
     */
    setPageDescription(pageDescriptionTranslationId: string) {
        if (pageDescriptionTranslationId) {
            const pageDescription: string = this.translate.instant(
                pageDescriptionTranslationId
            );
            this.metaService.updateTag({
                name: 'description',
                content: pageDescription
            });
        } else {
            this.metaService.updateTag({ name: 'description', content: '' });
        }
    }

    /**
     * Update the language in the lang attribute of the html element.
     */
    updateLanguage(): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        this.document.documentElement.lang = this.translate.currentLang;
    }

    @HostListener('dragenter', ['$event'])
    onDragEnter(e: DragEvent) {
        this.dragEnterCounter++;
        if (this.dragEnterCounter > 0) {
            this.dragAndDropService.setFileOver(true);
        }
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave(e: DragEvent) {
        this.dragEnterCounter--;
        if (this.dragEnterCounter === 0) {
            this.dragAndDropService.setFileOver(false);
        }
    }

    @HostListener('dragend', ['$event'])
    onDragEnd(e: DragEvent) {
        this.dragAndDropService.setFileOver(false);
    }
}

export interface CookieBarConfig {
    title: string;
    content: string;
    action: string;
}
