import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CMSPreviewComponent } from '@features/cms-preview/components/cms-preview/cms-preview.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
    declarations: [CMSPreviewComponent],
    imports: [CommonModule, SharedModule]
})
export class CmsPreviewModule {}
