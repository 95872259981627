import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollapseModule } from 'ngx-bootstrap';

import {
    AssetFilterFormComponent,
    CategoryFilterComponent
} from '@features/search/components';
import { SearchPageComponent } from '@features/search/containers';

import { SharedModule } from '@shared/shared.module';

@NgModule({
    declarations: [
        AssetFilterFormComponent,
        CategoryFilterComponent,
        SearchPageComponent
    ],
    imports: [CommonModule, CollapseModule, SharedModule]
})
export class SearchModule {}
