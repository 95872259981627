import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { SdkModule } from '@sodatech/sdk';

import {
    AssetKeywordsSelectorModalComponent,
    ContactFormModalComponent,
    FooterComponent,
    LightboxStickyFooterComponent
} from '@shared/components';

const components = [
    AssetKeywordsSelectorModalComponent,
    ContactFormModalComponent,
    FooterComponent,
    LightboxStickyFooterComponent
];

@NgModule({
    declarations: [...components],
    imports: [
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        TranslateModule,
        MatIconModule,
        SdkModule
    ],
    exports: [
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        TranslateModule,
        SdkModule,
        ...components
    ]
})
export class SharedModule {}
