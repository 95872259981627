import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';

import { UserService } from '@sodatech/sdk';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    @Output() close = new EventEmitter<any>();

    newPasswortUsername: string;

    newPasswordRequested: boolean;
    newPasswordRequestFailed: boolean;
    unknownUser: boolean;

    constructor(
        private user: UserService,
        private router: Router,
        private fb: FormBuilder,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {}

    cancelLogin() {
        this.user.logout();
        this.router.navigate([{ outlets: { modal: null } }], {
            preserveQueryParams: true
        });
    }

    closeLogin(): void {
        this.router.navigate([{ outlets: { modal: null } }]);
    }

    onLoginSuccessful(e: Event) {
        this.closeLogin();
    }

    getNewPassword() {
        this.user
            .login(this.newPasswortUsername, null)
            .then(status => {})
            .catch(error => {
                if (
                    error.message ===
                    'sodatechSdk.login.errors.newPasswordRequestNeeded'
                ) {
                    this.user
                        .requestPasswordResetToken(this.newPasswortUsername)
                        .then(() => {
                            this.newPasswordRequested = true;
                        })
                        .catch(() => {
                            this.newPasswordRequestFailed = true;
                        });
                } else {
                    this.unknownUser = true;
                }
            });
    }

    onRegister() {
        this.router.navigate([
            '',
            { outlets: { modal: null, primary: 'register' } }
        ]);
    }
}
