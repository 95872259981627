import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@shared/shared.module';

import { ActivateNewsletterPageComponent } from '@features/newsletter/containers/activate-newsletter-page/activate-newsletter-page.component';
import { DeactivateNewsletterPageComponent } from '@features/newsletter/containers/deactivate-newsletter-page/deactivate-newsletter-page.component';
import { NewsletterRequestPageComponent } from '@features/newsletter/containers/newsletter-request-page/newsletter-request-page.component';

@NgModule({
    declarations: [
        ActivateNewsletterPageComponent,
        DeactivateNewsletterPageComponent,
        NewsletterRequestPageComponent
    ],
    imports: [CommonModule, SharedModule]
})
export class NewsletterModule {}
