import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-cart-overview',
    templateUrl: './cart-overview-page.component.html',
    styleUrls: ['./cart-overview-page.component.scss']
})
export class CartOverviewPageComponent implements OnInit {
    constructor(private router: Router, private translate: TranslateService) {}

    ngOnInit() {}

    onCheckout(data: { type: string; orderId: number }) {
        if (data.type === 'payment') {
            this.router.navigate([
                `/${this.translate.currentLang}`,
                'cart',
                'checkout'
            ]);
        } else {
            this.router.navigate([
                `/${this.translate.currentLang}`,
                'cart',
                'promotion-code',
                data.orderId
            ]);
        }
    }
}
