import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-asset-keywords-selector-modal',
    templateUrl: './asset-keywords-selector-modal.component.html',
    styleUrls: ['./asset-keywords-selector-modal.component.scss']
})
export class AssetKeywordsSelectorModalComponent implements OnInit {
    constructor(private router: Router, private translate: TranslateService) {}

    selectedKeywords: string[];

    ngOnInit() {}

    onKeywordsChanges(keywords: string[]) {
        this.selectedKeywords = keywords;
    }

    onModalClose() {
        this.router.navigate([{ outlets: { modal: null } }], {
            queryParamsHandling: 'merge'
        });
    }

    onSubmit() {
        this.router.navigate([{ outlets: { modal: null } }]).then(() => {
            this.router.navigate([`/${this.translate.currentLang}`, 'search'], {
                queryParams: {
                    q: this.selectedKeywords
                        .map(keyword => {
                            if (keyword.indexOf(' ') !== -1) {
                                return `"${keyword}"`;
                            }
                            return keyword;
                        })
                        .join(' ')
                },
                queryParamsHandling: 'merge'
            });
        });
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
        event: KeyboardEvent
    ) {
        const keyPressed = event.keyCode;
        if (keyPressed === 27) {
            this.onModalClose();
        }
    }
}
