import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/internal/operators';
import { WebSeriesSliderConfig } from '@sodatech/sdk';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { ResponsiveService } from '@core/services';

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
    config$: Observable<WebSeriesSliderConfig>;

    dataSubscription: Subscription;

    currentLang: string;

    currentBreakpoint$: Observable<string>;

    constructor(
        private router: Router,
        private translateService: TranslateService,
        private responsiveService: ResponsiveService
    ) {
        this.dataSubscription = this.translateService.onLangChange.subscribe(
            data => {
                this.currentLang = data.lang;
            }
        );

        this.currentBreakpoint$ = this.responsiveService
            .checkBreakpoints(['mobile', 'tablet', 'regular'])
            .pipe(
                map(([isMobile, isTablet, isRegular]) => {
                    if (isMobile) {
                        return 'mobile';
                    }
                    if (isTablet) {
                        return 'tablet';
                    }
                    return 'regular';
                })
            );
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
    }
}
