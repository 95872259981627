import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { Router } from '@angular/router';
import {
    AuthenticationService,
    LightboxDataService,
    LightboxService,
    UserLoginService
} from '@sodatech/sdk';
import { Lightbox } from '@sodatech/sdk/lib/models/lightbox';
import { Observable, Subscription } from 'rxjs';
import detectIt from 'detect-it';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-lightbox-sticky-footer',
    templateUrl: './lightbox-sticky-footer.component.html',
    styleUrls: ['./lightbox-sticky-footer.component.scss']
})
export class LightboxStickyFooterComponent implements OnInit, OnDestroy {
    get itemsCount() {
        return {
            value: this.lightbox.assets ? this.lightbox.assets.length : 0
        };
    }

    @Input() showFooter = false;

    showLightbox = false;
    showLightboxTemporary = false;
    lightbox: Lightbox;
    enableDragAndDrop = false;

    userLoggedIn: boolean;

    @Output() showImprint = new EventEmitter();
    @Output() showLightboxes = new EventEmitter();
    @Output() toggleFooter = new EventEmitter();
    @Output() stickyFooterOpen = new EventEmitter();

    dataSubscription: Subscription = new Subscription();
    constructor(
        private router: Router,
        private lightboxDataService: LightboxDataService,
        private userLoginService: UserLoginService,
        private lightboxService: LightboxService,
        private translate: TranslateService
    ) {
        this.enableDragAndDrop = detectIt.deviceType !== 'touchOnly';
    }

    ngOnInit() {
        this.dataSubscription.add(
            this.lightboxDataService
                .getSelectedLightboxObservable()
                .subscribe(changedLightbox => {
                    this.lightbox = changedLightbox;
                })
        );

        this.dataSubscription.add(
            this.userLoginService.status.subscribe(status => {
                this.userLoggedIn = status === 'login';
            })
        );
    }

    get currentLang() {
        return this.translate.currentLang;
    }

    showAssetDetail(assetId) {
        this.router.navigate([{ outlets: { modal: ['detail', assetId] } }], {
            queryParamsHandling: 'merge'
        });
    }

    ngOnDestroy(): void {
        if (this.dataSubscription) this.dataSubscription.unsubscribe();
        this.lightboxService.setLightboxBarDragAndDropInititalized(false);
    }

    onAssetDragged() {
        this.showFooter = false;
        this.showLightboxTemporary = true;
    }

    onAssetDropped() {
        this.showLightboxTemporary = false;
        this.showLightbox = true;
    }

    onAssetDragCanceled() {
        this.showLightboxTemporary = false;
    }

    toggleLightBoxVisibility() {
        this.showLightbox = !this.showLightbox;
        if (this.showLightbox) {
            this.showFooter = false;
            this.stickyFooterOpen.emit(true);
        } else {
            this.showLightboxTemporary = false;
            this.stickyFooterOpen.emit(false);
        }
    }

    toggleFooterVisibility() {
        this.showFooter = !this.showFooter;
        if (this.showFooter) {
            this.showLightbox = false;
        }

        this.toggleFooter.emit();
    }
}
