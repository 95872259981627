import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-confirm-account-page',
    templateUrl: './confirm-account-page.component.html',
    styleUrls: ['./confirm-account-page.component.scss']
})
export class ConfirmAccountPageComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
