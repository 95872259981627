import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-reset-password-page',
    templateUrl: './request-reset-password-page.component.html',
    styleUrls: ['./request-reset-password-page.component.scss']
})
export class RequestResetPasswordPageComponent implements OnInit {
    constructor(private router: Router, private translate: TranslateService) {}

    ngOnInit() {}

    onRequestPasswordResetSuccessful(e: Event) {
        setTimeout(() => {
            this.router.navigate([`/${this.translate.currentLang}`]);
        }, 3000);
    }
}
