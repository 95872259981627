import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, take } from 'rxjs/operators';

import { AuthenticationService } from '@sodatech/sdk';

@Component({
    selector: 'app-register-page',
    templateUrl: './register-page.component.html',
    styleUrls: ['./register-page.component.scss']
})
export class RegisterPageComponent implements OnInit {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.authenticationService
            .getSignupSuccessfulStatus()
            .pipe(filter(Boolean), take(1))
            .subscribe(() => {
                if (window && window.setTimeout) {
                    window.setTimeout(() => {
                        this.router.navigate([
                            `/${this.translate.currentLang}`
                        ]);
                    }, 5000);
                }
            });
    }
}
