import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { UserLoginService } from '@sodatech/sdk';

// @todo: refactor
@Injectable({
    providedIn: 'root'
})
export class UserGuardService implements CanActivate {
    constructor(private userLogin: UserLoginService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.userLogin.status.pipe(
            filter(userStatus => {
                return userStatus === 'login' || userStatus === 'logout';
            }),
            map(userStatus => {
                if (userStatus === 'login') {
                    return true;
                }

                this.userLogin.login(state.url);

                return false;
            })
        );
    }
}
