import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@shared/shared.module';
import { GalleryPageComponent } from '@features/gallery/containers';
import { AddLightboxPageComponent } from './containers/add-lightbox-page/add-lightbox-page.component';
import { AssetDetailsPageComponent } from './containers/asset-details-page/asset-details-page.component';

@NgModule({
    declarations: [
        GalleryPageComponent,
        AddLightboxPageComponent,
        AssetDetailsPageComponent
    ],
    imports: [CommonModule, SharedModule]
})
export class GalleryModule {}
